import React from "react";
import CoursesComp from "../CoursesComp";
import Hero from "../Hero";
import { ExpertHelp } from "../../expertHelp/expertHelp";
import { AboutUs } from "./AboutUs/aboutUs";
import NewTeacherCool from "../NewTeacherCool";
import CourseToday from "../CourseToday";
import WhyChooseCourses from "../WhyChooseCourses";
import AdvertizeTwo from "../../Advertize/AdvertizeTwo";
import TeacherCoolCourses from "../TeacherCoolCourses";
import FrequentlyAskQuest from "../../FrequentlyAskQuestion/FrequentlyAskQuest";
import StudentsPlans from "../../Plans/StudentsPlans";
import Footer from "../../Footer/Footer";

export const Individual = ({authi}) => {
  console.log(authi)
  return (
    <React.Fragment>
      <Hero />
      <ExpertHelp />
      <CoursesComp />
      <NewTeacherCool />
      <WhyChooseCourses />
      <AboutUs />
      <AdvertizeTwo />
      <CourseToday />
      <TeacherCoolCourses />
      <FrequentlyAskQuest bgColor="bg-orange-100" pt="pt-20" />
      <StudentsPlans />
      <Footer />
    </React.Fragment>
  );
};
