import React from "react";
import { NavLink } from "react-router-dom";
import JoinFreeBtn from "../button/PrimaryButton";
import { motion } from "framer-motion";

const ExploreCourse = (props) => {

  return (
    <>
      <div className="container mx-auto  pb-10">
        <div
          className={`grid grid-cols-1 sm:grid-cols-${props.col1} md:grid-cols-${props.col2} lg:grid-cols-${props.col3} gap-5 py-5 max-sm:justify-items-center max-sm:px-4 `}
        >
          <Card data={props.courseData} />
        </div>
      </div>
    </>
  );
};



export default ExploreCourse;

const Card = (props) => {
  const defaultBanner = "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";
  return (
    <>
    
      {props?.data?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div>
              <div className="mt-4 border-solid   max-lg:max-w-[380px] min-h-80  md:h-fit border border-gray-200 md:shadow-md rounded-lg 
              cursor-pointer transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl shadow-2xl">
                <img
                  className="w-full h-40 md:h-[9rem] rounded-t-lg object-cover"
                  src={item?.banner ? item?.banner : defaultBanner}
                  alt="card-image"
                />

                <div className="py-3 pl-3">

                  {/* <JoinFreeBtn
                    title={item.btnText}
                    bgColor={"bg-blue-600"}
                    color={"text-white"}
                    className="px-[10px]"
                  /> */}
                  
                  <h2 className="font-semibold text-sm text-gray-900 pt-2">
                    {item?.title?.length > 40 ? `${item?.title?.slice(0, 40)}...` : item?.title}
                  </h2>
                  <p className="text-sm pt-1 text-gray-600 ">
                    {item?.about?.length > 80 ? `${item?.about.slice(0, 80)}...` : item?.about}
                  </p>
                  <div className="py-2">
                    <span>{item?.rating}</span>
                    <span className="px-2">*</span>
                    <span className="text-gray-600 text-sm">(17k views)</span>
                  </div>
                  <div className="flex  justify-end pr-5">
                    <NavLink onClick={() => localStorage.setItem("courseId", item?.id)} to={`/courses/${item?.id}/${item?.title}`} className="text-blue-500">
                      Learn more <span>&raquo;</span>
                    </NavLink>
                  </div>
                </div>

              </div>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};
