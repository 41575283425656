import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { RoutePaths } from "./route/RoutePath";
import { Individual } from "./components/pages/Individuals/Individual";
import { ToastContainer } from "react-toastify";
import LinkdinLogin from "./components/pages/login/socialSignupTeacher/LinkdinLogin";
import CategoryCourse from "./components/pages/CategoryCourse";
import apiClient from "./tokenHandle/axiosInstance";
import StudentHomePage from "./studentdashboard/component/StudentHomePage";
import BottomNav from "./components/pages/navigation/BottomNavigation";
import ForIndividuals from "./components/pages/Profile/individual/ForIndividuals";
import EmailVerificationUI from "./components/pages/EmailVerificationUI";

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const HeroExplore = React.lazy(() => import("./components/pages/HeroExplore"));
const Account = React.lazy(() => import("./components/pages/Account"));

function App() {
  // Initialize API client
  apiClient();

  

  return (
    <BrowserRouter basename="/">
      <AppRoutes />
      <ToastContainer
        limit={1}
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
      />
    </BrowserRouter>
  );
}

// Separate component to handle routes and conditional rendering
function AppRoutes() {
  const location = useLocation();
  const isStudentRoute = location.pathname.includes('/student');
  const isAdminRoute = location.pathname.includes('/admin');
  const isTeacherRoute = location.pathname.includes('/teacher');
  const token = localStorage.getItem("userAuth");
  console.log("apiClient", token);
  return (
    <>
      {!isStudentRoute && !isAdminRoute && !isTeacherRoute && <BottomNav />}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            exact
            path="/"
            name={RoutePaths.home.name}
            element={ !token ?<Individual /> : <StudentHomePage />}
          />
          
          <Route
            exact
            path="/account"
            name="account"
            element={<Account />}
          />

          <Route
            exact
            path="/courses"
            name="Explore"
            element={<HeroExplore />}
          />
          {/* <Route
            exact
            path="/:id"
            name="Explore"
            element={<HeroExplore />}
          /> */}
          <Route
            exact
            path="/courses/:id"
            name="Explore"
            element={<HeroExplore />}
          />
           <Route
            exact
            path="/serch/:id"
            name="Explore"
            element={<HeroExplore />}
          />
          <Route
            exact
            path="/courses"
            name="courses"
            element={<CategoryCourse />}
          />
          <Route
            exact
            path={`/linkedin/:id`}
            name="linkdin"
            element={<LinkdinLogin />}
          />
          <Route
            exact
            path={`/user/account/verify-account/activate/:uid/:id`}
            name="EmailVerification"
            element={< EmailVerificationUI />}
          />
          <Route
            exact
            path="*"
            name="forIndividuals"
            element={<DefaultLayout />}
          />
          <Route
            exact
            path="studentHome"
            name="StudentHomePage"
            element={<StudentHomePage />}
          />
          <Route
          exact
          path="/courses/:id/:title"
          name="forIndividuals"
          element={<ForIndividuals />}
        />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
