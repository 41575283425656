import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../assests/css/navigation.css";
import Logo from "../../../assests/images/Logo.png";
import downArrow from "../../../assests/images/downArrow.png";
import search from "../../../assests/images/search.png";
import JoinFreeBtn from "../../button/PrimaryButton";
import Navigation from "./TopNavigation";
import Login from "../login/Login";
import Signup from "../signup/Signup";
import { NavLink } from "react-router-dom";
import { RoutePaths } from "../../../route/RoutePath";
import { toast } from "react-toastify";
import { FaSearch, FaUserCircle } from "react-icons/fa";
import { Sidebar } from "../../Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { logoutStudentAsync } from "../../../store/features/AuthSlice";
import {
  changeShowLogin,
  changeCredential,
} from "../../../store/features/linkedinLoginSlice";
import { FaGreaterThan } from "react-icons/fa6";
import { getCategories, getCourseListDashboard } from "../../../store/authApi";


const BottomNav = () => {
  const [showInstructorJoin, setShowInstructorJoin] = useState(false);
  const [forgetPassword, setForgetPassword] = useState("login");
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isCrossIcon, setIsCrossIcon] = useState(false);
  const [showExplore, setShowExplore] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rotateArrow, setRotateArrow] = useState(false);
  const [isInputVisible, setInputVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { showLoginModal, signupModal } = useSelector(
    (state) => state.linkedinLogin
  );



  let hoverTimeout;

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => setIsHovered(false), 200); // Small delay to prevent quick hide
  };



  const baseUrl = process.env.REACT_APP_BASEURL;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setShowSidebar(!showSidebar);
    setIsCrossIcon(!isCrossIcon);
  };



  const toggleInputVisibility = () => {
    setInputVisible(!isInputVisible);
  };

  const [scrolling, setScrolling] = useState(false);

  const handleLoginClick = () => {
    dispatch(changeShowLogin(true));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window?.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ---------------login with linkedin------------------
  useEffect(() => {
    const fetchAccessToken = async (code) => {
      try {
        const response = await axios.post(
          `${baseUrl}user/linkedin/user_link/`,

          {
            code: code,
          }
        );
        const accessData = response.data;

        toast.success(response?.data?.message);
        if (response?.data?.access) {
          localStorage.setItem(
            "userAuth",
            JSON.stringify(response?.data?.access)
          );
          localStorage.setItem(
            "user_type",
            JSON.stringify(response?.data?.user_type)
          );
        }
        if (response?.data?.refresh) {
          localStorage.setItem(
            "refreshtoken",
            JSON.stringify(response?.data?.refresh)
          );
        }

        if (accessData) {
          if (!accessData.hasOwnProperty("user_type")) {
            dispatch(changeShowLogin(true));
            dispatch(changeCredential(true));
          } else {
            dispatch(changeCredential(false));
          }

          if (accessData.user_type === "student") {
            navigate("/student/dashboard");
          } else if (accessData.user_type === "admin") {
            navigate("/admin/dashboard");
          } else if (accessData.user_type === "corporate") {
            navigate("/corporate/dashboard");
          } else if (accessData.user_type === "teacher") {
            navigate("/teacher/dashboard");
          } else {
            navigate("/");
          }
        }
        // Use the access token to fetch user data
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    console.log("code", code);
    if (code) {
      fetchAccessToken(code);
    }
  }, [location]);

  // --------------getCourse------------------------
  useEffect(() => {
    setLoading(true);
    getCourseListDashboard()
      .then((res) => {
        if (res) {
          setCourseData(res)
          setLoading(false)
        }

      })
      .catch((error) => setLoading(false));
  }, []);

  // ---------------Get Categories------------------

  useEffect(() => {
    setLoading(true);
    getCategories()
      .then((res) => {
        if (res) {
          setData(res?.data);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  }, []);
  console.log("categoryData", data)

  const handleItemClick = (id) => {
    setShowExplore(!showExplore);
    console.log(id);
  };
  const handleFreeCourseClick = () => {
    setShowExplore(false)
    setSearchValue("free");

  }
  console.log("courseData", courseData)
  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchValue(term);

    if (term) {
      const results = courseData.filter((course) =>
        course.title.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };
  const handleItemClickCourseNavigation = (item) => {
    const query = encodeURIComponent(item.title);
    // const language = encodeURIComponent(item.language);
    const url = `/search?query=${query}`;

    navigate(url);
    setFilteredResults([]); // Clear the results after selection
  };

  const toggleExploreDropdown = () => {
    setShowExplore((prev) => !prev);
    setRotateArrow((prev) => !prev);
  };
  return (
    <React.Fragment>
      <div className=" sticky top-0 z-50 bg-white flex justify-between  items-center max-sm:p-4 sm:p-4 lg:p-0 ">
        <div className="lg:hidden items-center">
          <button
            className={`text-${scrolling ? "black" : "black"}`}
            onClick={handleClick}
          >
            {isCrossIcon ? (
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-12 h-10"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : (
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-12 h-10"
              >
                <path d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            )}
          </button>
        </div>
     

        {!isInputVisible ? (
          <div>
            <NavLink
              to={RoutePaths.home.path}
              className="logo_with_btn lg:hidden"
            >
              <img src={Logo} alt="nav-logo" className="cursor-pointer" />
            </NavLink>
          </div>
        ) : null}

        {/* input search fields for mobiles  */}

        <div className="search-field flex justify-center items-center lg:hidden relative">
          <input
            className={`outline-none border text-xl border-black rounded-md transition-all duration-500 ease-in-out ${isInputVisible
                ? "w-[18rem] px-4 py-2 opacity-100"
                : "w-0 px-0 opacity-0"
              }`}
            type="text"
            placeholder="Search..."
            style={{ display: isInputVisible ? "block" : "none" }}
          />
          <span
            className="magnifying-glass-container flex justify-center items-center bg-gray-100 rounded-full p-2 cursor-pointer hover:bg-gray-200 transition-colors duration-300 absolute right-0"
            onClick={toggleInputVisibility}
          >
            <FaSearch className="w-6 h-6 text-black hover:text-gray-500 transition-colors duration-300" />
          </span>
        </div>
      </div>

      {/* Sidebar */}
      <div className=" sticky top-0 z-50">
        {showSidebar && (
          <LeftTransition>
            <Sidebar
              isCrossIcon={isCrossIcon}
              setIsCrossIcon={setIsCrossIcon}
            />
          </LeftTransition>
        )}
      </div>

      {/* Main Content */}
      {windowWidth >= 1024 && (
        <div className="w-full py-4 sticky  z-50 top-0 bg-white shadow-md">
          <div className="w-full">
            <div className="flex w-full items-center pl-6 justify-between">
              <NavLink to={RoutePaths?.home?.path}>
                <img src={Logo} alt="nav-logo" className="cursor-pointer" />
              </NavLink>

              <div className="ml-[10px] relative lg:block">

                {/* Explore Button */}
                <div
                  onClick={toggleExploreDropdown}
                  className="font-semibold cursor-pointer appearance-none bg-customBlue text-bgWhite px-2 py-3 pr-10 rounded shadow leading-tight focus:outline-none focus:border-blue-500 w-21"
                >
                  Explore
                  <div
                    className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700 transition-transform duration-300 ease-in-out ${rotateArrow ? "rotate-180" : "rotate-0"
                      }`}
                  >
                    <img src={downArrow} alt="downarrow" className="pointer-events-none" />
                  </div>
                </div>

                {/* Dropdown Menu */}
                {showExplore && (
                  <div
                    className="absolute w-[18rem] -left-1/2 p-4 gap-5 top-[3.5rem] bg-gradient-to-r from-blue-100 to-blue-200 shadow-lg border border-gray-300 rounded-lg flex flex-col z-50"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex flex-col gap-3">
                      <div className="font-bold text-lg text-blue-800">Goals</div>
                      <NavLink
                        onClick={handleFreeCourseClick}
                        to={{
                          pathname: "/courses",
                          search: "?query=free",
                        }}
                        state={{ id: "free" }}
                        className="ps-4 cursor-pointer text-black hover:text-white rounded-sm py-2 px-5 hover:bg-blue-900 transition duration-300"
                      >
                        Take a free course
                      </NavLink>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="font-bold text-lg text-blue-800">Subjects</div>
                      {data.map((item, index) => (
                        <NavLink
                          onClick={() => handleItemClick(item.id)}
                          key={index}
                          to={`/courses/${item.id}`}
                          state={{ id: item.id }}
                          className="cursor-pointer text-black rounded-sm py-1 px-5 hover:bg-slate-200 transition duration-300 flex justify-between items-start"
                        >
                          <div className="ps-4">{item.cat_name}</div>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                )}

                {showExplore && (
                  <div
                    className="fixed inset-0 z-40"
                    onClick={() => {
                      setShowExplore(false);
                      setRotateArrow(false);
                    }}
                  />
                )}
              </div>


              {/* Search Field */}
              <div className=" relative">
                <div className=" w-[21rem] flex relative items-center border border-black rounded-md overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
                  <input
                    className="flex-1 px-4 py-2 text-gray-700 border  rounded-md rounded-r-none focus:outline-none focus:ring-2 transition duration-200 "
                    type="text"
                    placeholder="What do you want to learn today?"
                    value={searchValue}  // Bind input to searchValue state
                    onChange={handleInputChange}
                  />

                  <button className="p-[9px] rounded-sm bg-blue-600 hover:bg-blue-700 transition duration-200">
                    <img
                      src={search}
                      alt="search"
                      className="w-6 h-6 text-white"
                    />
                  </button>

                  {/* Display filtered results */}

                </div>
                {filteredResults.length > 0 && (
                  <ul className="absolute bg-white border mt-2 shadow-lg rounded w-full max-h-60 overflow-y-auto z-10">
                    {filteredResults.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleItemClickCourseNavigation(item)}
                        className="cursor-pointer px-4 py-2 hover:bg-gray-200 flex justify-between items-center"
                      >
                        <div className="flex flex-col">
                          <span className="font-medium text-gray-900">{item.title}</span>
                          {/* <span className="text-sm text-gray-600">{item.language}</span> */}
                        </div>
                        {/* <span className="text-xs text-gray-500">{new Date().toLocaleDateString()}</span> */}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Navlinks */}
              <div>
                <Navigation />
              </div>

              {/* Navbtn */}
              <div className="btns_nav p mr-10">
                {localStorage.getItem("userAuth") ? (
                  <div
                    className="user-profile"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {/* Profile Icon and Name */}
                    <div className="profile-icon-name">
                      <FaUserCircle className="profile-icon" size={24} />
                      <span className="user-name">{localStorage.getItem("userName").replace(/"/g, '')}</span>
                    </div>

                    {/* Popup Menu on Hover */}
                    {isHovered && (
                      <div className="popup-menu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <ul>
                          <li>
                            <NavLink to={localStorage.getItem("user_type") === `"student"` ? "/student/dashboard" : localStorage.getItem("user_type") === `"corporate"` ? "/corporate/dashboard" : "/teacher/dashboard"}>DashBoard</NavLink>
                          </li>

                          <li>
                            <button
                              onClick={() => dispatch(logoutStudentAsync(navigate))}
                              className="logout-btn"
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                ) : (
                  <button
                    onClick={() => handleLoginClick()}
                    className="login_btn_nav"
                  >
                    Login
                  </button>
                )}
                {!localStorage.getItem("userAuth") && <JoinFreeBtn
                  title={"Join for Free"}
                  bgColor={"bg-blue-600"}
                  color={"text-white"}
                />}

              </div>
            </div>
          </div>
        </div>
      )}

      {/* Login Modal part*/}
      {showLoginModal && (
        <div className="fixed inset-0 z-10 flex justify-center items-center bg-black bg-opacity-50">
          <Login
            // setShowSignUpPage={setShowSignUpPage}
            setForgetPassword={setForgetPassword}
            forgetPassword={forgetPassword}
          />
        </div>
      )}

      {/* Sign Up Page */}
      {signupModal && (
        <div className="fixed inset-0 z-10 flex justify-center items-center bg-black bg-opacity-50">
          <Signup
            // setShowSignUpPage={setShowSignUpPage}
            // showSignUpPage={showSignUpPage}
            showInstructorJoin={showInstructorJoin}
            setShowInstructorJoin={setShowInstructorJoin}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default BottomNav;

export const LeftTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ x: -10, opacity: 0 }} // initial position and opacity
      animate={{ x: 0, opacity: 1 }} // animate to this position and opacity
      exit={{ x: -50, opacity: 0 }} // animate out to this position and opacity
      transition={{ type: "tween", duration: 0.05 }} // animation transition type and duration
    >
      {children}
    </motion.div>
  );
};
