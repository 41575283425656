

import React, { useEffect, useState } from "react";
import { CiHome } from "react-icons/ci";
import { FaChevronRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import SelectedCourseDetail from "./pages/SelectedCourseDetail";
import useRazorpay from "../razorpay/PaymentComponent";
import { v4 as uuidv4 } from "uuid";
import { getCourseById } from "../store/authApi";
import { useDispatch } from "react-redux";
import { changeShowLogin } from "../store/features/linkedinLoginSlice";

const Forindividual = () => {
  const heroRef = React.useRef(null);
  const dispatch = useDispatch();
  const [specificCourse, setSpecificCourse] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    razorpay_order_id: uuidv4(),
    amount: "1",
    currency: "INR",
    receipt: uuidv4(),
    status: "created",
    other_info: {},
  });
  const { displayRazorpay } = useRazorpay();

  const handlePayment = () => {
    displayRazorpay(orderDetails);
  };

  useEffect(() => {
    getCourseById().then((res) => setSpecificCourse(res?.data));
    if (heroRef.current) {
      heroRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  console.log(specificCourse)

  return (
    <div ref={heroRef} className="mt-[30px] ">
      <div className="container mx-auto">
        <div className="  w-[85%] m-auto mb-2 ">
          <ul className=" flex items-center gap-1 md:gap-3">
            <li>
              <CiHome className="w-[22px] h-[22px] text-[#767777] cursor-pointer" />
            </li>
            <li>
              <FaChevronRight className="text-[10px] md:text-sm text-[#767777] " />
            </li>
            <li className="text-[10px] text-nowrap md:text-sm text-[#767777] font-Roboto cursor-pointer">
              Browse
            </li>
            <li>
              <FaChevronRight className="text-[10px] md:text-sm text-[#767777] " />
            </li>
            <li className="text-[10px] text-nowrap md:text-sm text-[#767777] font-Roboto cursor-pointer">
              Computer Science
            </li>
            <li>
              <FaChevronRight className="text-[10px] md:text-sm text-[#767777] " />
            </li>
            <li className="text-[10px] text-nowrap md:text-sm text-[#767777] font-Roboto cursor-pointer">
              React Js Course
            </li>
          </ul>
        </div>
      </div>

      <div className="bg-[#F4F6FC] ">
        <div className="container mx-auto">
          <div className="w-[90%] mx-auto grid grid-cols-1 md:grid-cols-2 pb-10 gap-5">
            <div className="flex flex-col gap-3 items-start pt-16">
              <div className="flex items-end">
                <button className="text-[#0966ED] bg-[#DBE6FE] h-8 px-2 w-auto text-sm font-medium rounded-full font-Roboto">
                  {specificCourse?.level}
                </button>
              </div>
              <span className="text-3xl md:text-4xl text-[#1E1E1E] text-opacity-90 font-bold font-Roboto">
                {specificCourse?.title}
              </span>
              <h1 className="text-base text-[#1E1E1E] text-opacity-60 font-Roboto">
                {specificCourse?.description}
              </h1>
              <div className="flex gap-6 items-center py-2">
                <div className="flex items-center gap-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/student/people.png"}
                    alt="img"
                  />
                  <span className="text-base font-Roboto font-medium text-[#656565]">
                    11K+ Students
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/student/people.png"}
                    alt="img"
                  />
                  <span className="text-base font-medium font-Roboto text-[#656565]">
                    {specificCourse?.duration} hr
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/student/calender.png"
                    }
                    alt="img"
                  />
                  <span className="text-base font-medium font-Roboto text-[#656565]">
                    Last Updated on 23 May, 2023
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-6">
                <button
                  onClick={() => {
                    const userAccess = localStorage.getItem("userAuth");
                    if (userAccess) {
                      handlePayment();
                    } else {
                      dispatch(changeShowLogin(true));
                    }
                  }}
                  className="text-base font-Roboto text-[#fff] font-semibold rounded-md bg-[#0966ED] w-[182px] h-[46px]"
                >
                  BUY COURSE 
                </button>
                <a
                  href="#courseDetail"
                  className="text-base font-medium font-Roboto leading-3 border-b border-[#1E1E1E] text-[#1E1E1E]"
                >
                  View course materials
                </a>
              </div>
            </div>

            <div className="pt-20 flex justify-center">
              {specificCourse?.banner == null ? (
                <img
                  alt="img"
                  className="max-w-full h-auto"
                  src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png"
                />
              ) : (
                <img
                  src={specificCourse?.banner}
                  alt="img"
                  className="max-w-full h-auto"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="courseDetail" className="container mx-auto">
        <div className="w-[90%] mx-auto">
          <SelectedCourseDetail specificCourse={specificCourse} />
        </div>
      </div>
    </div>
  );
};

export default Forindividual;

// import React, { useEffect, useState } from "react";
// import { CiHome } from "react-icons/ci";
// import { FaChevronRight } from "react-icons/fa";

// import { NavLink } from "react-router-dom";
// import SelectedCourseDetail from "./pages/SelectedCourseDetail";
// import useRazorpay from "../razorpay/PaymentComponent";
// import { v4 as uuidv4 } from "uuid";
// import { getCourseById } from "../store/authApi";

// const Forindividual = () => {
//   const [specificCourse, setSpecificCourse] = useState([]);
//   const [orderDeatails, setOrderDetails] = useState({
//     razorpay_order_id: uuidv4(),
//     amount: "1",
//     currency: "INR",
//     receipt: uuidv4(),
//     status: "Completed",
//     other_info: {},
//   });
//   const { displayRazorpay } = useRazorpay();

//   const handlePayment = () => {
//     displayRazorpay(orderDeatails);
//   };
//   useEffect(() => {
//     getCourseById().then((res) => setSpecificCourse(res?.data));
//   }, []);
//   return (
//     <div className=" mt-[30px]">
//       <div className="container mx-auto">
//         <div className="  w-[85%] m-auto mb-2 ">
//           <ul className=" flex    items-center gap-3 ">
//             <li>
//               <CiHome className="w-[22px] h-[22px] text-[#767777] cursor-pointer" />
//             </li>
//             <li>
//               <FaChevronRight className="text-sm text-[#767777] " />
//             </li>
//             <li className="text-sm text-[#767777] font-Roboto cursor-pointer">
//               Browse
//             </li>
//             <li>
//               <FaChevronRight className="text-sm text-[#767777] " />
//             </li>
//             <li className="text-sm text-[#767777] font-Roboto cursor-pointer">
//               Computer Science
//             </li>
//             <li>
//               <FaChevronRight className="text-sm text-[#767777] " />
//             </li>
//             <li className="text-sm text-[#767777] font-Roboto cursor-pointer">
//               React Js Course
//             </li>
//           </ul>
//         </div>
//       </div>
//       <div className="bg-[#F4F6FC] ">
//         <div className="container mx-auto">
//           <div className="w-[85%] mx-auto ">
//             <div className="grid grid-cols-2 pb-10  ">
//               <div className="  flex gap-3 items-start pt-16 flex-col">
//                 <div className="flex items-end  ">
//                   {/* <img src={Logo} alt="nav-logo" className="cursor-pointer" /> */}
//                   <button className="text-[#0966ED] bg-[#DBE6FE] h-8 w-[134px]  text-sm  font-medium  rounded-full font-Roboto">
//                     {specificCourse?.level}
//                   </button>
//                 </div>
//                 <div>
//                   <span className="text-4xl text-[#1E1E1E] text-opacity-90 font-bold font-Roboto">
//                     {specificCourse?.title}
//                   </span>
//                 </div>
//                 <div>
//                   <h1 className="text-base text-[#1E1E1E] text-opacity-60 font-Roboto">
//                     {specificCourse?.description}
//                   </h1>
//                 </div>
//                 <div className="flex gap-6 items-center py-2">
//                   <div className="flex items-center gap-3">
//                     <img
//                       src={
//                         process.env.PUBLIC_URL + "/images/student/people.png"
//                       }
//                       // src={specificCourse?.banner}
//                       alt="img"
//                     />
//                     <span className="text-base font-Roboto font-medium text-[#656565]">
//                       745,123 Students
//                     </span>
//                   </div>
//                   <div className="flex items-center gap-3">
//                     <img
//                       src={
//                         process.env.PUBLIC_URL + "/images/student/people.png"
//                       }
//                       alt="img"
//                     />
//                     <span className="text-base font-medium font-Roboto text-[#656565]">
//                       {specificCourse?.duration} hr
//                     </span>
//                   </div>
//                   <div className="flex items-center gap-3">
//                     <img
//                       src={
//                         process.env.PUBLIC_URL + "/images/student/calender.png"
//                       }
//                       alt="img"
//                     />
//                     <span className="text-base font-medium font-Roboto text-[#656565]">
//                       Last Updated on 23 May, 2023
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center gap-6">
//                   <button
//                     // onClick={handlePayment()}
//                     // onClick={() => handlePayment(item?.id)}
//                     className="text-base font-Roboto text-[#fff] font-semibold rounded-md bg-[#0966ED] w-[182px] h-[46px]"
//                   >
//                     LEARN COURSE
//                   </button>
//                   <NavLink
//                     to={"/"}
//                     className="text-base font-medium font-Roboto leading-3 border-b border-[#1E1E1E] text-[#1E1E1E] "
//                   >
//                     View course materials
//                   </NavLink>
//                 </div>
//               </div>

//               <div className="pt-20">
//                 <img
//                   // src={
//                   //   process.env.PUBLIC_URL + "/images/student/coursestudent.png"
//                   // }
//                   src={specificCourse?.banner}
//                   alt="img"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container mx-auto">
//         <div className="w-[85%] mx-auto ">
//           <SelectedCourseDetail />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Forindividual;
