import React from "react";
import JoinFreeBtn from "../button/PrimaryButton";
import img5 from "../../assests/images/Ellipse 5.png";
import img1 from "../../assests/images/Ellipse 1.png";

const AdvertizeTwo = () => {
  return (
    <>
      {/* <div className=" bg-blue-100  relative py-5 pr-[7.25rem] max-sm:px-4 max-sm:h-auto mb-16  "> */}
      <div className=" bg-blue-100 relative w-full ">
        <img
          src={img1}
          // src={process.env.PUBLIC_URL + "/images/Ellipse 4.png"}
          alt="ellipe"
          className="absolute left-0 top-0 "
        />
        <div className=" md:ml-12 px-8 max-sm:px-4">
          <div className="flex justify-between max-sm:flex-col sm:flex-col xl:flex-row gap-5">
            <div className=" flex flex-row gap-0 lg:w-1/2 overflow-hidden  sm:w-full max-sm:w-full ">
              <div className="flex flex-col  w-fit h-fit">
                <img
                  src={process.env.PUBLIC_URL + "/images/group_study.png"}
                  alt="no img"
                  className="w-[15rem] h-[240px] max-sm:h-[130px] relative top-[-19px] max-sm:right-[10px]"
                />
                <img
                  src={process.env.PUBLIC_URL + "/images/student2.png"}
                  alt="no img"
                  className=" w-[15rem]  max-sm:h-[150px] h-[240px]  relative top-[0px] right-0 max-sm:right-[10px] "
                />
              </div>
              <img
                src={process.env.PUBLIC_URL + "/images/student.png"}
                alt="no img"
                className="w-[300px] max-sm:w-auot max-sm:h-[250px] h-[420px] ml-3"
              />
            </div>

            <div className="mt-8 max-ms:mt-4 w-1/2 lg:w-1/2 sm:w-full max-sm:w-full pl-8 max-sm:pl-0">
              <div>
                <h1 className="text-4xl max-sm:text-3xl font-semibold leading-[60px] text-gray-800">
                  Learners outcomes on TeacherCool
                </h1>
                <p className="text-base  py-5 text-gray-500">
                  On TeacherCool, learners experience a transformative
                  educational journey that equips them with practical, in-demand
                  skills. Each course is designed with real-world applications
                  in mind, helping students gain relevant knowledge that
                  directly impacts their career growth. Learners benefit from
                  personalized learning paths, allowing them to progress at
                  their own pace, whether they are just starting out or
                  advancing their skills.
                  <br />
                  Through interactive content and hands-on projects, students
                  can actively apply what they learn, building confidence and
                  proficiency. TeacherCool provides a collaborative environment
                  where learners can connect with peers and instructors,
                  fostering a sense of community and shared growth.
                </p>
                <div>
                  {!localStorage.getItem("userAuth") && (
                    <JoinFreeBtn
                      title={"Join For Free"}
                      // bgColor={"bg-blue-500"}
                      bgColor={"bg-[#0966ED]"}
                      color={"text-white"}
                      className="px-[15px]"
                      padding="px-4 py-3"
                      fontSize="text-lg"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
        src={img5}
          // src={process.env.PUBLIC_URL + "/images/Ellipse 4.png"}
          alt="ellipe"
          className="absolute right-0 bottom-0  max-sm:h-[300px]"
        />
       
      </div>
    </>
  );
};

export default AdvertizeTwo;
