import React from "react";

const Skillgain = ({ courseSkills }) => {
  return (

    <div className="pt-10 pb-6 mx-auto w-full max-w-4xl">
      <p className="font-Roboto font-medium text-lg text-center">
        Skills you'll gain
      </p>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
        {courseSkills.length > 0 &&
          courseSkills?.map((item, index) => (
            <p
              key={index}
              className="bg-[#F4F6FC] text-xs text-[#0966ED] font-Roboto rounded-full px-3 py-2 text-center"
            >
              {item}
            </p>
          ))}

      </div>
    </div>
  );
};

export default Skillgain;
