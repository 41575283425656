import React from "react";
import { RiH1 } from "react-icons/ri";
import img1 from "../../../../assests/images/Ellipse 4.png";
import { changeShowLogin } from "../../../../store/features/linkedinLoginSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const AboutRight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <div className="relative">
        <div>
          <h1 className="text-blue-700 text-2xl">About us</h1>
          <h1 className="text-6xl max-sm:text-4xl font-bold py-3">
            Who We Are
          </h1>
          <p className="text-base text-[#696969]  ">
            TeacherCool - Empowering Minds Through Innovative Trainings for all
            your need whether it is IT/NON-IT for Individual, Corporate and
            Universities.
            <br />
            <br />
            Welcome to TeacherCool, where knowledge meets innovation, and
            learning becomes an exciting journey! As a premier online platform
            for IT-based training, TeacherCool is committed to revolutionizing
            education by providing comprehensive, cutting-edge courses that
            empower individuals to thrive in the dynamic world of Information
            Technology. TeacherCool comes as a part of Gypr , dedicated towards
            transforming online learning for both IT and non-IT students. With
            the rich legacy and expertise of GYPR, we are carving our own
            learning path, providing top-notch educational experiences for
            modern learners.
          </p>
          {/* <p className="text-md font-medium text-gray-700">
            {" "}
            Phasellus maximus neque id eros vestibulum ultricies. Nunc fermentum
            dolor id ante egestas, in suscipit mauris vulputate. Etiam ac metus
            mauris
          </p> */}

          <button
            onClick={() => {
              if (localStorage.getItem("userAuth")) {
                navigate("/forIndividual");
              } else {
                dispatch(changeShowLogin(true));
              }
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-3 px-4 rounded focus:outline-none mt-5"
            type="button"
          >
            Start Learning Now
          </button>
        </div>
        {/* <div className=" absolute  right-[-15px] max-sm:top-[200px] max-sm:right-[-15px] -z-10 "> */}
        
      </div>
    </>
  );
};
