import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useRazorpay from "../../razorpay/PaymentComponent";
import { fetchExchangeRate } from "../../store/authApi";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { changeShowLogin } from "../../store/features/linkedinLoginSlice";
import { useNavigate } from "react-router-dom";

const Card = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { displayRazorpay } = useRazorpay();
  const priceInINR = 100;
  const [userCurrency, setUserCurrency] = useState("INR"); // Default currency INR
  const [conversionRate, setConversionRate] = useState();

  const [orderDeatails, setOrderDetails] = useState({
    razorpay_order_id: uuidv4(),
    amount: "1",
    currency: "INR",
    receipt: uuidv4(),
    status: "Completed",
    other_info: {},
  });

  // Function to fetch the user's currency and conversion rate
  useEffect(() => {
    fetchExchangeRate(userCurrency).then((rate) => {
      setConversionRate(rate);
    });
  }, [priceInINR]);

  const handlePayment = (id) => {
    localStorage.setItem("courseId", id);
    displayRazorpay(orderDeatails, id);
  };

  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }

  return (
    <>
      {props?.data?.map((item, index) => {
        const bannerImage =
          "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";
        return (
          <React.Fragment key={index}>

            {/* <div>
              <div className="relative mt-4 border-solid w-[300px] max-lg:max-w-[380px] min-h-[24rem]  border border-gray-200 hover:shadow-md rounded-lg">
                <span
                  className={`absolute top-2 left-2 text-white text-xs font-bold py-1 px-3 rounded-full shadow-md ${item?.level === "beginner"
                    ? "bg-green-500"
                    : item?.level === "intermediate"
                      ? "bg-yellow-500"
                      : "bg-red-500"
                    }`}
                >
                  {item?.level}
                </span>
                <img
                  className="w-full h-52 rounded-t-lg object-cover"
                  src={item?.banner ? item.banner : bannerImage}
                  alt="card-image"
                />

                <div className="p-3">
                  <h2 className="font-semibold text-sm text-gray-900 pt-3">
                    {item?.title}
                  </h2>
                  <p className="text-sm pt-1 text-gray-600 pr-1">
                    {truncateText(item?.description, 12)}{" "}
                    <NavLink
                      onClick={() => localStorage.setItem("courseId", item?.id)}
                      to="/forIndividuals"
                      className="text-blue-500 text-sm font-semibold"
                    >
                      Learn more <span>&raquo;</span>
                    </NavLink>
                  </p>
                  <div className="py-2 flex flex-col gap-2">
                    <div className="flex items-center text-sm text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-blue-500 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-.086.31-.197.613-.332.907M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <span className="ml-1">17k views</span>
                    </div>

                    <div className="flex items-center text-sm text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-green-500 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8c1.5 0 3-.667 3-1.5S13.5 5 12 5 9 5.667 9 6.5s1.5 1.5 3 1.5zm0 0c-1.5 0-3 .667-3 1.5s1.5 1.5 3 1.5 3-.667 3-1.5-1.5-1.5-3-1.5zm0 0V5m0 4v10m6 2H6"
                        />
                      </svg>
                      <span className="font-bold text-gray-800">
                        Price: {(item.price * conversionRate).toFixed(2)}{" "}
                        {userCurrency}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-4 space-x-3">
                    <div
                      onClick={() => {
                        if (localStorage.getItem("userAuth")) {
                          handlePayment(item?.id);
                        } else {
                          dispatch(changeShowLogin(true));
                        }
                      }}
                      className="text-xs bg-blue-500 text-center text-white font-bold py-2 px-2 rounded-full shadow-lg hover:shadow-2xl hover:scale-105 transition duration-300 ease-in-out cursor-pointer flex-1"
                    >
                      Buy Now
                    </div>
                    <div
                      onClick={() => {
                        const userType = localStorage.getItem("user_type");
                        if (!localStorage.getItem("userAuth")) {
                          dispatch(changeShowLogin(true));
                        } else {
                          navigate(`${userType.replace(/"/g, "")}/dashboard`);
                        }
                      }}
                      className="text-xs bg-blue-500 text-center text-white font-bold py-2 px-2 rounded-full shadow-lg hover:shadow-2xl hover:scale-105 transition duration-300 ease-in-out cursor-pointer flex-1"
                    >
                      Try for Free
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* new added card  */}

            <div className='md:w-[16rem] w-full md:flex flex-shrink-0 flex-col border-2 rounded-lg relative '>
              <span
                className={`absolute top-2 left-2 text-white text-xs font-light py-1 px-3 rounded-full shadow-md ${item?.level === "beginner"
                  ? "bg-green-500"
                  : item?.level === "intermediate"
                    ? "bg-yellow-500"
                    : "bg-red-500"
                  }`}
              >
                {item?.level}
              </span>

              <img src={item?.banner ? item.banner : bannerImage} alt="loading" className="md:w-full  md:h-[10rem]" />
              <div className="flex px-2 gap-2 flex-col py-3">
                <button className='bg-[#0966ED] px-4 py-1 rounded-md text-[white] w-max text-xs '>Beginner Course</button>
                <p className='text-xs text-black font-bold'>{item?.title}</p>
                <div className="flex items-center gap-3">
                  <b className="flex items-center gap-1">4.7 <span> <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="#0966ED">
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                  </svg></span></b>
                  <span className='text-[grey] text-xs'>(17k reviews)</span>
                </div>
                <p className="text-sm pt-1 text-gray-600 pr-1">
                  {truncateText(item?.description, 10)}{" "}
                  <NavLink
                    onClick={() => localStorage.setItem("courseId", item?.id)}
                    to="/forIndividuals"
                    className="text-blue-500 text-sm font-semibold"
                  >
                    Learn more <span>&raquo;</span>
                  </NavLink>
                </p>

              </div>
            </div>


          </React.Fragment>
        );
      })}
    </>
  );
};

export default Card;
