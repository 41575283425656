import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserdetails } from './authApi';
// import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASEURL;



// to get student details
export const getStudentDetailsAsync = createAsyncThunk(
    "student/getUserDetails",
    async (data, { rejectWithValue }) => {
        try {
            const response = await getUserdetails(data);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);



// to update student details
// export const updateStudentDetailsAsync = createAsyncThunk(
//     "student/updateUserDetails",
//     async ({id, formDatas, token }, { rejectWithValue }) => {
//         try {
//             const response = await updateUserdetails(id, formDatas, token);
//             return response;
//         } catch (error) {
//             return rejectWithValue(error.response?.data);
//         }
//     }
// );
// console.log("here is url", BASE_URL)

const initialState = {
    studentDetails: {},
    loading: false,
    error: null,
    updateSuccess: false,  // Added to track the success of update action
};

const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        resetUpdateSuccess: (state) => {
            state.updateSuccess = false; // Reset update success state
        }
    },
    extraReducers: (builder) => {
        // Get student details
        builder.addCase(getStudentDetailsAsync.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getStudentDetailsAsync.fulfilled, (state, action) => {
            state.loading = false;
            state.studentDetails = action.payload;
        });
        builder.addCase(getStudentDetailsAsync.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
          
    },
});

export const { resetUpdateSuccess } = studentSlice.actions; // Export the reset action
export default studentSlice.reducer;




// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { getUserdetails,updateUserdetails } from './authApi';

// // to get
// export const getStudentDetailsAsync = createAsyncThunk(
//     "get/userDetails",
//     async (data, { rejectWithValue }) => {
//         try {
//             const response = await getUserdetails(data);
//             return response;
//         } catch (error) {
//             return rejectWithValue(error.response.data);
//         }
//     }
// );

// // to update

// export const updateStudentDetailsAsync = createAsyncThunk(
//     "put/userDetails",
//     async ({id,data}, { rejectWithValue }) => {
//         try {
//             const response = await updateUserdetails(id,data);
//             return response;
//         } catch (error) {
//             return rejectWithValue(error.response.data);
//         }
//     }
// );

// const initialState = {
//     studentDetails: null,
//     loading: false,
//     error: null,
// };

// const studentSlice = createSlice({
//     name: "student",
//     initialState,
//     extraReducers: (builder) => {
//         builder.addCase(getStudentDetailsAsync.pending, (state) => {
//             state.loading = true;
//             state.error = null;
//         });
//         builder.addCase(getStudentDetailsAsync.fulfilled, (state, action) => {
//             state.loading = false;
//             state.studentDetails = action.payload;
//         });
//         builder.addCase(getStudentDetailsAsync.rejected, (state, action) => {
//             state.loading = false;
//             state.error = action.payload;
//         });
//     },
// });

// export default studentSlice.reducer;
