import React, { useState, useEffect } from "react";
import "../../assests/css/courses.css";
import roundedbar from "../../assests/images/roundedbar.png";
import Card from "../card/Card";
import { NavLink } from "react-router-dom";
import { getCategories, getCourseByCatId } from "../../store/authApi";
import ClipLoader from "react-spinners/ClipLoader";

import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";


ChartJS.register(ArcElement, Tooltip, Legend);

const CoursesComp = () => {
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [catId, setCatId] = useState("");
  const [catName, setCatName] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("Web Designing");
  

  const handleCourseClick = (courseName) => {
    console.log(courseName)
    setSelectedCourse(courseName.cat_name);
  };
  useEffect(() => {
    getCategories().then((res) => {
      setCategories(res?.data);
      setCatId(res?.data[0]?.id);
      setCatName(res?.data[0]?.cat_name);
    });
  }, []);

  const courseDatas = {
    "Web Designing": [
      { label: "Beginner: ₹2.5 - 4 LPA", value: 56940 },
      { label: "Intermediate: ₹4 - 8 LPA", value: 78608 },
      { label: "Advanced: ₹8 - 15 LPA", value: 98000 },
    ],
    "Business Analytics": [
      { label: "Beginner: ₹4 - 6 LPA", value: 56700 },
      { label: "Intermediate: ₹6 - 12 LPA", value: 78900 },
      { label: "Advanced: ₹12 - 20+ LPA", value: 99100 },
    ],
    "Cloud Computing Technology": [
      { label: "Beginner: ₹5 - 8 LPA", value: 66787 },
      { label: "Intermediate: ₹8 - 15 LPA", value: 79800 },
      { label: "Advanced: ₹15 - 30+ LPA", value: 100000 },
    ],
    "Ethical Hacking": [
      { label: "Beginner: ₹4 - 6 LPA", value: 58090 },
      { label: "Intermediate: ₹6 - 12 LPA", value: 87008 },
      { label: "Advanced: ₹12 - 25+ LPA", value: 120980 },
    ],
    "Data Analytics": [
      { label: "Beginner: ₹3.5 - 6 LPA", value: 56006 },
      { label: "Intermediate: ₹6 - 10 LPA", value: 89790 },
      { label: "Advanced: ₹10 - 20+ LPA", value: 110980 },
    ],
    "Artificial intelligence": [
      { label: "Beginner: ₹5 - 8 LPA", value: 59006 },
      { label: "Intermediate: ₹8 - 15 LPA", value: 80790 },
      { label: "Advanced: ₹15 - 30+ LPA", value: 130980 },
    ],
    "Web Scraping": [
      { label: "Beginner: ₹3 - 5 LPA", value: 60230 },
      { label: "Intermediate: ₹5 - 10 LPA", value: 77980 },
      { label: "Advanced: ₹10 - 18 LPA", value: 120000 },
    ],
    "Database Management": [
      { label: "Beginner: ₹3 - 5 LPA", value: 58000 },
      { label: "Intermediate: ₹5 - 10 LPA", value: 77200 },
      { label: "Advanced: ₹10 - 18 LPA", value: 110000 },
    ],
    "Statistics": [
      { label: "Beginner: ₹3 - 6 LPA", value: 59800 },
      { label: "Intermediate: ₹6 - 12 LPA", value: 77000 },
      { label: "Advanced: ₹12 - 20+ LPA", value: 110000 },
    ],
  };
  
  const courseDataAvailable = courseDatas[selectedCourse] !== undefined;
  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    if (catId) {
      setLoading(true);
      getCourseByCatId(catId)
        .then((res) => {
          setLoading(false);
          console.log("data is here", res?.data)
          setCourseData(res?.data);
        })
        .catch((error) => setLoading(false));
    }
  }, [catId]);
  return (
    <>
      <div className="container mx-auto max-sm:px-4 ">
        <h2 className="font-semibold text-3xl">
          Get Started with your Courses today!
        </h2>

        <div className="relative h-[2.23rem] mb-2 ">
          <div className="for_listing_courses ">
            {categories &&
              categories.map((cat, index) => {
                return (
                  <p
                    className={activeIndex === index ? "active" : ""}
                    onClick={() => {
                      setCatId(cat?.id);
                      handleItemClick(index);handleCourseClick(cat);
                      setCatName(cat?.cat_name);
                    }}
                  >
                    {cat.cat_name}
                  </p>
                );
              })}
          </div>
          <hr
            style={{
              backgroundColor: "#e5e7e8",
              width: "100%",
              height: "4px",
              position: "absolute",
              bottom: "0px",
              zIndex: "-1",
            }}
          />
        </div>
        <div className="border-2 border-solid border-gray-300  rounded ">
          <div className="mt-10 mx-10  max-sm:mx-2 max-lg:mt-5">
            <div className="w-full flex flex-wrap max-sm:flex-row justify-center md:justify-between">
              <h1 className="font-semibold text-3xl max-lg:text-center max-sm:w-full max-sm:mb-4">
                {catName}
              </h1>
              <NavLink
                to="/forIndividual"
                className="font-medium text-base w-[10rem] h-8 flex items-center justify-center text-gray-900 shadow-md"
                onClick={() => window.scrollTo(0, 0)}
              >
                Explore all courses <span>&raquo;</span>
              </NavLink>
            </div>
            <div className="flex mt-4 justify-between items-center">
              <p className="text-textColor max-lg:text-center">
                Unlock a world of knowledge and skills—start your journey with
                our expert-led courses today! Dive in now to shape your future
                and reach your goals.
              </p>
            </div>
            <div className="mt-10 mb-2 flex flex-row justify-between  gap-[100px] max-xl:gap-[100px] max-lg:flex-col max-lg:items-center max-lg:gap-5">
              {/* <div className="min-w-40">
                <img src={roundedbar} alt="graph" className="pb-8" />
              
                <hr className="border-gray-300  m-auto h-4  " />
                <div className="flex pt-2">
                  <p className="text-gray-600 ">Job openings:</p>
                  <p className="pl-2">82,489</p>
                </div>
              </div> */}

              {courseDataAvailable ? (
                      <PieChartComponent selectedCourseData={courseDatas[selectedCourse]} />
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full h-full">
                        <div className="bg-gray-200 rounded-full w-20 h-20 flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-10 w-10 text-gray-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </div>
                        <h2 className="text-2xl sm:text-3xl lg:text-4xl mt-3 font-semibold text-gray-700 mb-2 text-center">
                          Oops! Data not found
                        </h2>
                        <p className="text-base sm:text-lg lg:text-xl text-gray-500 text-center">
                          Sorry, we couldn't find the data you're looking for.
                          Please try again later.
                        </p>
                      </div>
                    )}
              <div className="flex flex-col  ">
                <h2 className="pt-10 font-semibold ">
                  Recommended Professional Certificates
                </h2>
                <div className="flex w-full md:w-[50vw] overflow-x-auto gap-12 max-xl:gap-5 max-sm:gap-2 max-sm:flex-wrap max-sm:justify-center">
                  {loading ? (
                    <div className="h-[17rem] w-full flex flex-col items-center justify-center">
                      <ClipLoader color="#007bff" loading={loading} size={50} />{" "}
                      {/* Spinner */}
                      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-gray-700 mb-2 mt-3">
                        Loading...
                      </h2>
                      <p className="text-base sm:text-lg lg:text-xl text-gray-500">
                        Please wait while we fetch the data.
                      </p>
                    </div>
                  ) : courseData.length > 0 ? (
                    <Card data={courseData} />
                  ) : (
                    <div className="h-[17rem] w-full flex flex-col items-center justify-center">
                      <h2 className="text-2xl sm:text-3xl lg:text-4xl mt-3 font-semibold text-gray-700 mb-2">
                        Data not found!
                      </h2>
                      <p className="text-base sm:text-lg lg:text-xl text-gray-500 mb-6">
                        Sorry, we couldn’t find the data you’re looking for.
                        Please try again later.
                      </p>
                    </div>
                  )}
                </div>
                {/* <div className="flex justify-end items-center pt-6 pb-4"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesComp;

const PieChartComponent = ({ selectedCourseData }) => {
  const labels = selectedCourseData?.map((item) => item.label);
  const values = selectedCourseData?.map((item) => item.value);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Salary Distribution",
        data: values,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(75, 192, 192, 0.5)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Salary Distribution by Level" },
    },
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg">
      
      <Pie data={data} options={options} />
    </div>
  );
};