import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  forgetPasswordApi,
  forgetPasswordEnd,
  googleLogin,
  instructorCategories,
  instructorSpecalities,
  linkedinLoginApi,
  registerTeacherBySocial,
  // refreshTokenApi,
  studentRegsiterApi,
  typeOfCountryApi,
  typeOfUsersApi,
  userLogin,
  userProfileUpdate,
  verifyEmailOtpApi,
  getUserdetails,
  getCourseList,
  getTechnologyList,
  getUsersData,
  updateCourseById,
  deleteCourseById,
  createCourseContent,
  updateCourseContent,
  addCourseVideos,
  getCourseVideos,
  getTeacherProfileComplete,
  getStateList,
  teacherApprovedByAdmin,
  logoutStudent,
  getEnrollement,
  addPayment,
  createSubAdmin,
} from "../authApi";

const initialState = {
  student: null,
  user_type: null,
  provider: null,
  token: null,
  status: null,
  errorMsg: null,
  loading: false,
  insCat: null,
  insSp: null,
  apiResponse: null,
  countryList: [],
  paymentData: [],
  loading: false,
  error: null,
};

const handleAsyncThunk = (builder, asyncThunk, reducerKey) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.status = "idle";
      state.loading = true;
      state.errorMsg = null;
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.status = "200";
      state.loading = false;
      // state.errorMsg = action.payload;
      state.apiResponse = action.payload;
      if (reducerKey) {
        state[reducerKey] = action.payload;
      }
      if (
        asyncThunk === userLoginAsync ||
        asyncThunk === registerTeacherBySocialAsync
      ) {
        const { user_type, access, provider } = action?.payload;
        state.user_type = user_type;
        state.token = access;
        state.provider = provider;
      }
      // state[action.meta.reducerKey] = action.payload;
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.errorMsg = action?.payload;
    });
};
export const googleLoginAsync = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await googleLogin(data);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.status === 400) {
        const errorMessage = error?.response?.data;
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const registerTeacherBySocialAsync = createAsyncThunk(
  "auth/bySocial",
  async (data, { rejectWithValue }) => {
    try {
      const response = await registerTeacherBySocial(data);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.status === 400) {
        const errorMessage = error?.response?.data;
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const userLoginAsync = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userLogin(data);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.status === 401) {
        const errorMessage = error?.response?.data;
        toast.error(error?.response?.data?.detail);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const forgetPasswordAsync = createAsyncThunk(
  "user/forget-password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await forgetPasswordApi(data);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.status === 400) {
        const errorMessage = error?.response?.data;
        toast.error(error?.response?.data?.error);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const verifyEmailOtpApiAsync = createAsyncThunk(
  "user/verify-otp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await verifyEmailOtpApi(data);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.status === 400) {
        const errorMessage = error?.response?.data;
        toast.error(error?.response?.data?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const forgetPasswordEndAsync = createAsyncThunk(
  "user/forget-pass",
  async (data, { rejectWithValue }) => {
    try {
      const response = await forgetPasswordEnd(data);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.status === 400) {
        const errorMessage = error?.response?.data;
        toast.error(error?.response?.data?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const studentRegisterAsync = createAsyncThunk(
  "auth/studentRegister",
  async (data, { rejectWithValue }) => {
    try {
      const response = await studentRegsiterApi(data);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.status === 400) {
        const errorMessage = error?.response.data;
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
// -------------ligin with linkedId---------------------
export const linkedinLoginAsync = createAsyncThunk(
  "auth/linkedin",
  async (data) => {
    const response = await linkedinLoginApi(data);
    return response?.auth_url;
  }
);
// -------------ligin with linkedId---------------------

export const typeofCountryAsync = createAsyncThunk(
  "auth/countrylist",
  async (data) => {
    const response = await typeOfCountryApi(data);
    return response?.data;
  }
);
export const userTypeAsync = createAsyncThunk("auth/userType", async (data) => {
  const response = await typeOfUsersApi(data);
  return response?.data;
});

// export const userProfileUpdateAsync = createAsyncThunk(
//   "auth/userType",
//   async (data) => {
//     const response = await userProfileUpdate(data);
//     return response.data;
//   }
// );
export const userProfileUpdateAsync = createAsyncThunk(
  "auth/userProfileUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userProfileUpdate(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data); 
    }
  }
);


export const instructorCatAsync = createAsyncThunk(
  "auth/instructorCat",
  async (data) => {
    const response = await instructorCategories(data);
    return response;
  }
);

export const instructorSpAsync = createAsyncThunk(
  "auth/instructorSp",
  async (data) => {
    const response = await instructorSpecalities(data);
    return response.data;
  }
);

// --------------get user details AsycThunk---------------
export const getUserDetailsAsync = createAsyncThunk(
  "get/userDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getUserdetails(data);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.status === 400) {
        const errorMessage = error?.response?.data;
        toast.error(error?.response?.data?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// --------------get user details AsycThunk---------------

// --------------get course lisr AsyncThunk----------------------
export const getCourseListAsync = createAsyncThunk(
  "get/courseList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getCourseList(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------get course list AsyncThunk----------------------

// --------------get Technology list AsyncThunk------------------
export const getTechnologyListAsync = createAsyncThunk(
  "get/technologyList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getTechnologyList(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------get Technology list AsyncThunk------------------

// --------------get users data AsyncThunk-----------------------
export const getUsersDataAsync = createAsyncThunk(
  "get/usersData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getUsersData(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------get users data AsyncThunk-----------------------

// --------------update courseById AsyncThunk-------------------
export const updateCourseByIdAsync = createAsyncThunk(
  "update/courseById",
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateCourseById(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------update courseById AsyncThunk------------------

// --------------delete courseById AsyncThunk-------------------
export const deleteCourseByIdAsync = createAsyncThunk(
  "delete/courseById",
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteCourseById(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------delete courseById AsyncThunk-------------------
// createCourseContent

// --------------create course content AsyncThunk-------------------
export const createCourseContentAsync = createAsyncThunk(
  "add/courseContent",
  async (data, { rejectWithValue }) => {
    try {
      const res = await createCourseContent(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------create course content AsyncThunk-------------------

// --------------update course content AsyncThunk----------------------
export const updateCourseContentAsync = createAsyncThunk(
  "update/courseContent",
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateCourseContent(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------update course content AsyncThunk----------------------

// --------------add course video AsyncThunk---------------------------
export const addCourseVideosAsync = createAsyncThunk(
  "add/courseVideos",
  async (data, { rejectWithValue }) => {
    try {
      const res = await addCourseVideos(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------add course video AsyncThunk---------------------------

// --------------get course video AsyncThunk---------------------------
export const getCourseVideosAsync = createAsyncThunk(
  "get/courseVideos",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getCourseVideos(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------get course video AsyncThunk---------------------------

// --------------get teacher profile completed-------------------------
export const getTeacherProfileCompleteAsync = createAsyncThunk(
  "get/teacherProfileComplete",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getTeacherProfileComplete(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------get teacher profile completed-------------------------

// --------------get state list-------------------------
export const getStateListAsync = createAsyncThunk(
  "get/stateList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getStateList(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------get state list-------------------------

// --------------teacher approved by admin--------------
export const teacherApprovedByAdminAsync = createAsyncThunk(
  "approve/teacherByAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const res = await teacherApprovedByAdmin(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------teacher approved by admin--------------

// --------------user logout----------------------------
export const logoutStudentAsync = createAsyncThunk(
  "user/logout",
  async (data, { rejectWithValue }) => {
    try {
      const res = await logoutStudent(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------user logout--------------

// --------------add payment data----------------------------
export const addPaymentAsync = createAsyncThunk(
  "add/payment",
  async (data, { rejectWithValue }) => {
    try {
      const res = await addPayment(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------add payment data--------------

// --------------create sub-admin async--------
export const createSubAdminAsync = createAsyncThunk(
  "create/sub-admin",
  async (data, { rejectWithValue }) => {
    try {
      const res = await createSubAdmin(data);
      return res.data;
    } catch (error) {
      if (error?.message && error?.message?.status === 400) {
        const errorMessage = error?.message?.data;
        toast.error(errorMessage?.message);
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue;
      }
    }
  }
);
// --------------create sub-admin async--------

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.user_type = null;
      state.token = null;
    },
    user_type: (state, action) => {
      state.user_type = action.payload;
    },
    token: (state, action) => {
      state.token = action.payload;
    },
    provider: (state, action) => {
      state.provider = action.payload;
    },
    studentRegister: (state, action) => {
      state.student = action.payload;
    },
    // googleLoginTy: (state, action) => {
    //   state.student = action.payload;
    // },

    // clearErrorMsg: (state) => {
    //   state.errorMsg = null;
    // },
    clearErrorMsg(state) {
      state.errorMsg = null;
    },
    showErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
  },
  extraReducers: (builder) => {
    handleAsyncThunk(builder, studentRegisterAsync, "student");
    handleAsyncThunk(builder, userTypeAsync, "userType");
    handleAsyncThunk(builder, instructorCatAsync, "instructorCat");
    handleAsyncThunk(builder, instructorSpAsync, "instructorSp");
    handleAsyncThunk(builder, googleLoginAsync);
    handleAsyncThunk(builder, userLoginAsync, "loginUserData");
    handleAsyncThunk(builder, registerTeacherBySocialAsync);
    handleAsyncThunk(builder, verifyEmailOtpApiAsync);
    handleAsyncThunk(builder, forgetPasswordAsync);
    handleAsyncThunk(builder, typeofCountryAsync, "countrylist");
    handleAsyncThunk(builder, getUserDetailsAsync, "loginUserDetails");
    handleAsyncThunk(builder, getCourseListAsync, "courseList");
    handleAsyncThunk(builder, getTechnologyListAsync, "technologyList");
    handleAsyncThunk(builder, getUsersDataAsync, "usersData");
    handleAsyncThunk(builder, updateCourseByIdAsync);
    handleAsyncThunk(builder, deleteCourseByIdAsync);
    handleAsyncThunk(builder, createCourseContentAsync);
    handleAsyncThunk(builder, updateCourseContentAsync);
    handleAsyncThunk(builder, addCourseVideosAsync);
    handleAsyncThunk(builder, getCourseVideosAsync, "courseVideosData");
    handleAsyncThunk(
      builder,
      getTeacherProfileCompleteAsync,
      "teacherProfileComplete"
    );
    handleAsyncThunk(builder, getStateListAsync, "stateList");
    handleAsyncThunk(builder, teacherApprovedByAdminAsync);
    handleAsyncThunk(builder, addPaymentAsync);
    handleAsyncThunk(builder, createSubAdminAsync);
  },
});

export default authSlice.reducer;

export const { studentRegister, logout, clearErrorMsg, showErrorMsg } =
  authSlice.actions;
