
import React, { useEffect, useState } from 'react';
import pic from '../../../src/assests/images/aboutImage.png';
import AppHeader from './AppHeader'
import AppSidebar from './AppSidebar';
import StudentCatButtons from './StudentCatButtons';
import Footer from "../../components/Footer/Footer";
import { getCategories, getCourseListDashboard } from '../../store/authApi';
import { NavLink } from 'react-router-dom';

function StudentHomePage() {
  const [showAll, setShowAll] = React.useState(false);

  const [catId, setCatId] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState([]);
  useEffect(() => {
    setLoading(true);
    getCourseListDashboard()
      .then((res) => {
        if (res) {

          setCourseData(res);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  }, []);

  useEffect(() => {
    getCategories().then((res) => setData(res?.data));
  }, []);
  // Mock data for cards
  const cardsData = [
    {
      img: `${pic}`,
      institution: "California Institute of the Arts",
      title: "Graphic Design",
      type: "Specialization",
    },
    {
      img: `${pic}`,
      institution: "California Institute of the Arts",
      title: "Introduction to Typography",
      type: "Course",
    },
    {
      img: `${pic}`,
      institution: "California Institute of the Arts",
      title: "UI / UX Design",
      type: "Specialization",
    },
    {
      img: `${pic}`,
      institution: "California Institute of the Arts",
      title: "Introduction to Imagemaking",
      type: "Course",
    },
  ];

  // Show either 4 or all cards based on "showAll" state
  const displayedCards = showAll ? cardsData : cardsData.slice(0, 4);
  return (
    <>
      {/* <AppHeader /> */}

      <div className="w-full px-4 md:px-8 lg:px-16 py-8">
        <h2 className="text-xl font-semibold mb-4">Explore with a {data?.[0]?.cat_name}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {courseData
            ?.filter((card) => card.category === data[0]?.id) // Filter based on data[0]?.id
            ?.map((card, index) => (
              <Card
                key={index}
                img={card.banner}
                institution={card.skills}
                title={card.title}
                type={card.mode}
                level={card.level}
                id={card.id}
              />
            ))}
        </div>
        {!showAll && (
          <button
            onClick={() => setShowAll(true)}
            className="mt-4 text-blue-500 hover:underline"
          >
            Show 8 more
          </button>
        )}
      </div>

      {/* section 2  */}
      <div className="w-full px-4 md:px-8 lg:px-16 py-8">
        <h2 className="text-xl font-semibold mb-4">Explore with a {data?.[1]?.cat_name}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {courseData
            ?.filter((card) => card.category === data[1]?.id) // Filter based on data[0]?.id
            ?.map((card, index) => (
              <Card
                key={index}
                img={card.banner}
                institution={card.skills}
                title={card.title}
                type={card.mode}
                level={card.level}
                id={card.id}
              />
            ))}
        </div>
        {!showAll && (
          <button
            onClick={() => setShowAll(true)}
            className="mt-4 text-blue-500 hover:underline"
          >
            Show 8 more
          </button>
        )}
      </div>

      {/* section 3  */}
      <div className="w-full px-4 md:px-8 lg:px-16 py-8">
        <h2 className="text-xl font-semibold mb-4">Explore with a {data?.[2]?.cat_name}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {courseData
            ?.filter((card) => card.category === data[2]?.id) // Filter based on data[0]?.id
            ?.map((card, index) => (
              <Card
                key={index}
                img={card.banner}
                institution={card.skills}
                title={card.title}
                type={card.mode}
                level={card.level}
                id={card.id}
              />
            ))}
        </div>
        {!showAll && (
          <button
            onClick={() => setShowAll(true)}
            className="mt-4 text-blue-500 hover:underline"
          >
            Show 8 more
          </button>
        )}
      </div>

      <StudentCatButtons data={data} />

      {/* <Footer/> */}
    </>


    //     <>
    //   <AppHeader />
    //   <div className="flex">

    //     <div className="w-1/4">
    //       <AppSidebar />
    //     </div>

    //     {/* Main content on the right */}
    //     <div className="w-3/4 px-4 md:px-8 lg:px-16 py-8">
    //       <h2 className="text-xl font-semibold mb-4">Based on Your Recent Views</h2>
    //       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
    //         {displayedCards.map((card, index) => (
    //           <Card 
    //             key={index}
    //             img={card.img}
    //             institution={card.institution}
    //             title={card.title}
    //             type={card.type}
    //           />
    //         ))}
    //       </div>
    //       {!showAll && (
    //         <button
    //           onClick={() => setShowAll(true)}
    //           className="mt-4 text-blue-500 hover:underline"
    //         >
    //           Show 8 more
    //         </button>
    //       )}
    //     </div>



    //   </div>
    // </>

  );
}

export default StudentHomePage;


//card
const Card = ({ img, institution, title, type, level, id }) => {
  const defaultBanner = "https://miro.medium.com/v2/resize:fit:720/format:webp/1*U4gZLnRtHEeJuc6tdVLwPw.png";
  return (
    <NavLink onClick={() => localStorage.setItem("courseId", id)} to={`/courses/${id}/${title}`} className="border relative border-gray-200 rounded-lg shadow-md overflow-hidden">
      <img className="w-full h-32 object-cover" src={img ? img : defaultBanner} alt={title} />
      <div className="p-4">
        <span className="text-xs text-gray-500">{institution ? institution.split(',')[0] : 'Unknown Institution'}</span>
        <h3 className="text-sm font-semibold text-gray-900 mt-1">{title}</h3>
        <p className="text-xs text-gray-600">{type}</p>
      </div>
      <span
        className={`absolute top-2 left-2 text-white text-xs font-bold py-1 px-3 rounded-full shadow-md bg-blue-600`}
      >
        {level}
      </span>
    </NavLink>


  );
};

export { Card }
