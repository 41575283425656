import React from "react";
import JoinFreeBtn from "../button/PrimaryButton";
import "../../assests/css/hero.css";
import Group_one from "../../assests/images/Group_one.png";
import Navigation from "../../components/pages/navigation/TopNavigation";
import { NavLink, useNavigate } from "react-router-dom";
import heroImg from '../../../src/assests/images/heroImg.png'
import { motion } from "framer-motion";
import BottomNav from "./navigation/BottomNavigation";
import Forindividual from "../../studentdashboard/Forindividual";



const Hero = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <BottomNav /> */}
      {/* <Forindividual /> */}
      <div className='bg-gradient-to-b from-[#C6D2FF] via-[#FFFFFF] to-[#FFFFFF] min-h-[500px] flex items-center justify-center py-10 flex-col'>

        <div className="flex flex-col md:w-[60%] items-center justify-center gap-4">
          <h1 className='md:text-4xl text-3xl font-bold'>
            Learn Without Limits
          </h1>

          <p className='text-sm w-[70%] text-center'> Welcome to TeacherCool, where knowledge meets innovation, and
            learning becomes an exciting journey! As a premier online platform
            for IT-based training, TeacherCool is committed to revolutionizing
            education by providing comprehensive, cutting-edge courses that
            empower individuals to thrive in the dynamic world of Information
            Technology. TeacherCool comes as a part of GYPR, dedicated towards
            transforming online learning for both IT and non-IT students. With
            the rich legacy and expertise of GYPR, we are carving our own
            learning path, providing top-notch educational experiences for
            modern learners.
          </p>

          <div className="flex flex-col md:flex-row items-center justify-center gap-5 ">
          {!localStorage.getItem("userAuth")  && <JoinFreeBtn
                  title={"Join for Free"}
                  bgColor={"bg-blue-600"}
                  color={"text-white"}
                />}
                {/* <button className='bg-[#0966ED] rounded-md text-[white] py-2 w-full'>
            <NavLink to={`/user/account/verify-account/activate/${1}/${2}`} className=" text-white" >
            
             Email EmailVerification
              </NavLink>
              </button> */}
            <button className='border border-[#0966ED] outline-none text-[#0966ED] py-2 px-4 rounded-md'>
              <NavLink to="/universities">Try TeacherCool for Universities</NavLink>
     

            </button>
          </div>

          <div className="mt-7 w-full h-auto md:w-[90%] md:h-[20rem] md:flex items-center justify-center relative">
            <img src={heroImg} alt=""
              className='ml-3 rounded-md w-[80%] h-[15rem] md:w-[75%] md:h-[20rem] relative' />

            <div className="absolute top-[7rem] md:top-[9rem] right-2 bg-[white] rounded-lg py-1 px-2 shadow-xl text-center">
              <h1 className='text-[#0966ED] text-1xl md:text-2xl font-bold '>95%</h1>
              <b className='text-[6px] md:text-[19px] '>success rate</b>
              <p className='text-[6px] md:text-[12px] text-center'> Teachercool training services</p>
            </div>
          </div>

        </div>

      </div>

    </>
  );
};

export default Hero;
